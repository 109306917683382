<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h3 v-if="dados.length <= 0">Carregando dados...</h3>
                        <b-table :items="dados" :fields="colunas">
                            <template v-slot:cell(pessoa.nome)="data">
                                  {{ data.item.pessoa.nome }} {{ data.item.pessoa.sobrenome }}<br>{{ data.item.pessoa.email }}
                            </template>
                            <template v-slot:cell(pessoa.cpf)="data">
                                {{ data.item.pessoa.cpf }} <br>{{ data.item.pessoa.nascimento }}
                            </template>
                            <template v-slot:cell(pessoa.telefone)="data">
                                {{ data.item.pessoa.ddd2 }} {{ data.item.pessoa.telefone }} <br>{{ data.item.pessoa.ddd }} {{ data.item.pessoa.fone }}
                            </template>
                            <template v-slot:cell(pessoa.endereco)="data">
                                  {{ data.item.pessoa.endereco }}, {{ data.item.pessoa.numero }},
                                  {{ data.item.pessoa.bairro }} - {{ data.item.pessoa.cidade }}/{{ data.item.pessoa.estado }}
                            </template>
                        </b-table>

                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>

<script>

import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";

import firebase from 'firebase/app';

export default {
    name: "clientes",
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            dados: [],
            colunas: [
                {key: 'pessoa.nome', label: 'Nome'},
                {key: 'pessoa.cpf', label: 'CPF/nascimento'},
                {key: 'pessoa.telefone', label: 'Tel'},
                {key: 'pessoa.endereco', label: 'Endereço'},
            ],
        };
    },
    mounted() {
        firebase.firestore().collection('cadastros').where('pessoa.nome','!=','').orderBy('pessoa.nome','asc').get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);
                this.dados.push(doc.data());
            });
        });
    },
    methods: {

    }
}
</script>

<style scoped>

</style>